import { t, Trans } from "@lingui/macro";
import React from "react";
import {
    Center,
    extractDomainTLD,
    LivligButton,
    Page,
    PageHeader,
    Segment,
    SegmentHeader,
} from "@ingka-livlig/frontend-lib";
import { useParams } from "react-router-dom";

export function Deprecated() {
    const title = t`Customer pressure`;
    const { sellingUnitCode, groupId } = useParams<{ sellingUnitCode: string; groupId: string }>();

    function buildHref() {
        function baseUrl() {
            if (window.location.hostname === "localhost") {
                return `//localhost:3000`;
            } else {
                const domainTLD = extractDomainTLD();
                return `//item-sales.livlig.ingka.${domainTLD}`;
            }
        }

        if (sellingUnitCode) {
            //If it's an old link to food stuff, direct to the food HFB (96)
            if (groupId === "BISTROS" || groupId === "RESTAURANTS" || groupId === "SFMARKETS") {
                return `${baseUrl()}/locations/SellingUnit/${sellingUnitCode}/HFB/96#flow`;
            } else {
                return `${baseUrl()}/locations/SellingUnit/${sellingUnitCode}#flow`;
            }
        } else {
            return baseUrl();
        }
    }

    return (
        <Page title={title} headerElement={<PageHeader headerText={t`Customer pressure`}></PageHeader>}>
            <SegmentHeader>
                <Trans>Deprecated</Trans>
            </SegmentHeader>

            <Segment variant={"primary"}>
                <p>
                    <Trans>
                        The "Customer Pressure" site is being decommissioned and the majority of features are moved into
                        the main "Livlig" site.
                    </Trans>
                </p>
                <Center>
                    <LivligButton type={"primary"} href={buildHref()}>
                        Take me to Livlig
                    </LivligButton>
                </Center>
            </Segment>
        </Page>
    );
}
