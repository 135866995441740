import { BrowserRouter, Redirect, Route, Switch, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { extractSelectedLanguage, ScrollToTop } from "@ingka-livlig/frontend-lib";
import { QueryParamProvider } from "use-query-params";
import { withStore } from "react-context-hook";
import { Deprecated } from "./Deprecated";
import { i18n } from "@lingui/core";
import { I18nProvider, useLingui } from "@lingui/react";
import { localeActivate } from "./i18n";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

/**
 * Allow for rerouting urls with the old five digits business unit code to using full "selling unit codes".
 * @param node
 * @constructor
 */
const RerouteBusinessUnit: React.FC<{ node: React.ReactElement }> = ({ node }) => {
    const { sellingUnitCode } = useParams<{
        sellingUnitCode: string;
    }>();
    if (sellingUnitCode?.length === 5) {
        return (
            <Redirect
                to={
                    document.location.pathname.replace(sellingUnitCode, "STO" + sellingUnitCode) +
                    window.location.search
                }
            />
        );
    } else {
        return node;
    }
};

//Since this site is being decommissioned we'll display the Deprecated view for all requests, which redirect to Sales.
function MainRouter() {
    const lang = extractSelectedLanguage();
    /**
     * This is put here in order to force Lingui to remount when language changes.
     * With Lingui 4 the default is to only rerender when language us changed. This works nicely when <Trans> elements
     * are used, but doesn't work when the t`...` templated string is used. The result is a lot of untranslated strings.
     * We handle this by remounting everything, which shouldn't be an issue as it's expected that users seldomly
     * change languages.
     *
     * In addition, we rely on the selected locale to fetch proper names of HFBs and PAs from the server, so we need to
     * trigger a refetch anyway.
     */
    useLingui();

    useEffect(() => {
        // With this method we dynamically load the catalogs
        localeActivate(lang);
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <I18nProvider i18n={i18n}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <Switch>
                        <Route
                            path={["/till-pressure/:sellingUnitCode/:groupId?", "/"]}
                            component={() => <RerouteBusinessUnit node={<Deprecated />} />}
                        />
                    </Switch>
                </QueryParamProvider>
            </I18nProvider>
        </BrowserRouter>
    );
}

export default withStore(MainRouter, {});
